.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.card-deck .card {
  margin-top: 20px;
}

.carousel .slide {
  background: transparent !important;
}

.delete-button {
  width: 40px;
}

.scroll {
  height: 200px;
  overflow-y: scroll;
}

.badgeTooltip {
  color: #fff !important;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.badgeTooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 105%;
  opacity: 0;
  transition: opacity 1s;
}

.badgeTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.fa-bell {
  color: black !important;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.fa-bell .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}

.fa-bell:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.label-lsp {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 18.5px;
}

.app-header {
  /* background-color: #295caa; */
  background-image: linear-gradient(to right, #7acee6 30%, #29a7f2 100%);
  border-bottom: 0;
}

.app-header .navbar-brand {
  width: 55px !important;
  margin-left: 70px;
}

.app-header .nav-item {
  color: white;
}

.img-accessor {
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  height: auto;
  display: block;
  /* margin-top: 7%; */
  box-shadow: 10px 10px 10px #80808066;
}

.img-asesi {
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  height: auto;
  display: block;
}

.img-document {
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  height: auto;
  display: block;
}

.iconAsesor {
  margin-top: 0.6rem;
  margin-left: 6rem;
}

.iconAsesesi {
  margin-top: 0.6rem;
  margin-left: 6rem;
}

.iconTuk {
  margin-top: 0.6rem;
  margin-left: 6rem;
}

.text {
  width: 26.5px;
  height: 10px;
  font-family: SegoeUI;
  font-size: 15.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color:#16A521  ;

}

.text:hover {
  color: black;
}

.text:active {
  color: crimson;
}

.textAsesi {
  margin-right: 6rem;
}

.textTuk {
  margin-right: 3rem;
}

.countAsesors {
  margin-left: 2.2rem;
}

.countAsesis {
  margin-right: 3.4rem;
}

.countTuk {
  margin-left: 0rem;
}

.viewMore {
  text-align: center;
}

.headers {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.jmlahCarousel {
  height: 26.5px;
  font-family: SegoeUI;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #696a67;
  margin-right: 10%;
}

.textCarousel {
  height: 10.5px;
  opacity: 0.76;
  font-family: SegoeUI;
  font-size: 27px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: right;
  color: #696a67;
  margin-bottom: 10%;
}


.cardCarousel {
  background-color: transparent;
  border: none;
  margin-bottom: 0px;
}

.cardTabel {
  margin-bottom: 0rem;
}

.colCarousel {
  /* background-color: #e4e5e6; */
  border: none;
}

.colorIcon {
  color: dimgrey;
}

.app-header .navbar-toggler-icon {
  background-image: url(../assets/img/icon/align-justify-solid.svg);
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide {
  background: transparent !important;
}

.textJadwal {
  font-size: 25px;
}
