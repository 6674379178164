/* .tri-left:before {
  content: "";
  width: 10px;
  height: 1px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
} */

.ant-popover {
  z-index: 1503 !important;
}
  
/* .ant-modal-mask:has( .ant-modal-wrap > .modal-chat ) {
  z-index: 1503 !important;
} */

.ant-modal-wrap:has(.modal-chat)  {
  z-index: 1503 !important;
}