/* Navbar */
.sidebar {
    background: #ffffff !important;
  
  }
  
  .sidebar .nav-link {
    color: #696a67;
    background: white;
  
  }
  
  .sidebar .nav-link.active {
    color: black;
    background-color: #e4e5e6 !important;
    border-radius: 30px 0px 0px 30px;
    background: white;
    /* width:180px; */
  }

  .sidebar .nav {
    width: 218px ;
    
  }

  .sidebar .nav-dropdown-toggle::before {
    position: absolute;
    top: 50%;
    right: 5rem;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    margin-right: -40px;
    margin-top: -4px;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .3s; 
  }
  
  .sidebar .nav-link.active .nav-icon {
    color: white;
  }
  
  .nav-item a:hover {
    color: black;
    border-radius: 30px 0px 0px 30px;
  }
  
  .nav-item li {
    background-color: white !important;
  }
  
  .sidebar .nav-link .nav-icon {
    color: black;

  }
  
  .sidebar .nav-link.active .nav-icon {
    color: black;
  }
  
  .sidebar .nav-dropdown.open .nav-link {
    color: #696a67;
    border-left: 0;
  }
  
  .sidebar .nav-dropdown.open {
    color: #014b1c;
    background-color: transparent;
  }
  
  .sidebar .sidebar-minimizer {
    background-color: transparent;
  }
  
  .mt-auto {
    background-color: white !important;
  }
  
  .sidebar .sidebar-minimizer:hover {
    background-color: #20a8d8 !important;
    border-radius: 30px 0px 0px 30px;
  }
  
  .BreadCrumb {
    background-color: red !important;
  }
  
  /* Login */
  .card.bg-primary {
    border-color: #fff;
  }
  
  .sweet-loading {
    padding-top: 10%;
    padding-left: 47%;
  }
  
  .divLoading {
    margin-top: 35%;
    margin-bottom: 50%;
  }
  
  .forgot {
    color: #676d61;
    margin-top: -6%;
    font-size: 7px;
    margin-bottom: -2%;
  }
  
  .btn-priimary {
    color: #000000;
    background-color: #00ae41;
    border-color: #fff;
  }
  
  .btn-priimary:hover {
    background-color: #59cc50;
    color: rgb(8, 8, 8);
  }
 

  .btn-cancel {
    color:#676d61!important;
    border-color:#676d61 !important; 
    border-color: #ced4da;
  }
  .btn-cancel:hover{
    background-color:#ffffff !important;
    color: #707070 !important;
  }
  
  .btn-simpan {
    color: #ffffff;
    background-color:#0093e1;
    border-color: #0058a2;
  }

  .btn-simpan:hover{
    background-color: #0093e1;
    color: #ffffff;
  }
  img.login {
    width: 74%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  assign-pleno{
    color: #707070 !important;
    float: right !important;  
  }
  assign-pleno:hover{
    background-color: #0093e1;
    color: #ffffff;
  }


  .backgroundColor {
    background-image: linear-gradient(to top, #295caa 0%, #7acee6 100%);
  }
  
  img.LogoplayStore {
    width: 22%;
  }
  
  .logo {
    color: #75715e;
    margin-bottom: 10%;
    margin-top: -13%;
  }
  
  .logoNST {
    width: 44%;
  }
  
  .alignText {
    text-align: center;
    margin-top: 7%;
    margin-bottom: 15%;
  }
  
  .colNST {
    position: absolute;
    font-family: sans-serif;
    margin-top: 16px;
    margin-left: -27px;
    text-align: center;
  }
  
  .footerCopyright {
    text-align: center;
    color: gray;
    font-size: 10px;
    font-family: inherit;
    font-style: italic;
  }
  
  .loader {
    padding-bottom: 20%;
  }
  
  .bg-logo {
    background-image: linear-gradient(120deg, #59cc50 0%, #00ae41 100%);
  }
  
  .playstoreLogo {
    width: 60%;
    margin-top: 10%;
  }
  
  .cardHeader {
    background-color: transparent;
  }
  
  .Login {
    width: 100%;
    height: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #787a76;
  }
  
  .SignYourAccount {
    color: #43425d;
    font-family: "Courier New", Courier, monospace;
    size: 15.3sp;
    font-weight: bold;
    width: 100%;
    height: 13.5px;
    opacity: 0.89;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.35;
    margin-top: 10px;
  }
  
  .inputBox {
    height: 29.5px;
    opacity: 0.78;
    border-radius: 15px;
    border: solid 0.5px #707070;
    background-color: #ffffff;
    outline: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .center-block {
    display: table; /* Instead of display:block */
    margin-left: auto;
    margin-right: auto;
  }
  
  .getIT {
    width: 70.5px;
    height: 27px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #66a428;
  }
  
  b {
    width: 80.5px;
    height: 16.5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #847f7f;
  }
  
  input::placeholder {
    width: 149px;
    height: 11.5px;
    opacity: 0.58;
    font-family: Arial;
    font-size: 12.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
  }
  
  .react-modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #666666;
    opacity: 0.7;
    z-index: 10000;
  }
  
  .modal-visible {
    display: block;
  }
  
  .modal-hidden {
    display: none;
  }
  
  .react-modal .loader-msg {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
  }
  
  /* DashBoard */
  .img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  .card-deck .card {
    margin-top: 20px;
  }
  
  .carousel .slide {
    background: transparent !important;
  }
  
  .delete-button {
    width: 40px;
  }
  
  .scroll {
    height: 200px;
    overflow-y: scroll;
  }
  
  .badgeTooltip {
    color: #fff !important;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .badgeTooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 105%;
    opacity: 0;
    transition: opacity 1s;
  }
  
  .badgeTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .fa-bell {
    color: black !important;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .fa-bell .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 1s;
  }
  
  .fa-bell:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .label-lsp {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 18.5px;
  }
  
  .app-header {
    /* background-color: #295caa; */

    background-image: linear-gradient(to right,  #1b8cce 20%,#1b8cce 100% );
    border-bottom: 0;
  }
  
  .app-header .navbar-brand {
    width: 55px !important;
    margin-left: 70px;
  }
  
  .app-header .nav-item {
    color: white;
  }
  .aktif {
    color: white !important;
    background-color: #20a8d8;
    border-radius: 5px 5px 5px 5px  !important;
  }
  .aktif:hover{
    border-radius: 5px 5px 5px 5px  !important;
  }
 
  .img-accessor {
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 7%;
    box-shadow: 10px 10px 10px #80808066;
  }
  
  .img-asesi {
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  .img-document {
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  .iconAsesor {
    margin-top: 0.6rem;
    margin-left: 6rem;
  }
  
  .iconAsesesi {
    margin-top: 0.6rem;
    margin-left: 6rem;
  }
  
  .iconTuk {
    margin-top: 0.6rem;
    margin-left: 6rem;
  }
  
  .text {
    width: 26.5px;
    height: 10px;
    font-family: SegoeUI;
    font-size: 15.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #577a11;
  }
  
  .text:hover {
    color: black;
  }
  
  .text:active {
    color: crimson;
  }
  
  .textAsesi {
    margin-right: 6rem;
  }
  
  .textTuk {
    margin-right: 3rem;
  }
  
  .countAsesors {
    margin-left: 2.2rem;
  }
  
  .countAsesis {
    margin-right: 3.4rem;
  }
  
  .countTuk {
    margin-left: 0rem;
  }
  
  .viewMore {
    text-align: center;
  }
  
  .headers {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
  }
  
  .jmlahCarousel {
    height: 26.5px;
    font-family: SegoeUI;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #696a67;
  }
  
  .textCarousel {
    height: 16.5px;
    opacity: 0.76;
    font-family: SegoeUI;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: right;
    color: #696a67;
  }
  
  .cardCarousel {
    background-color: transparent;
    border: none;
    margin-bottom: 0px;
  }
  
  .cardTabel {
    margin-bottom: 0rem;
  }
  
  .colCarousel {
    /* background-color: #e4e5e6; */
    border: none;
  }
  
  .colorIcon {
    color: dimgrey;
  }
  
  .app-header .navbar-toggler-icon {
    background-image: url(../assets/img/icon/align-justify-solid.svg);
  }
  
  .carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
  }
  
  .carousel .slide {
    background: transparent !important;
  }
  
  .textJadwal {
    font-size: 25px;
  }
  /* Button */
  /* .ant-btn {
    color: #f86c6b !important;
    background-color: transparent !important;
    border-color: #f86c6b !important;
  }
   */
  /* .ant-btn:hover {
    color: white !important;
    background-color: #f86c6b !important;
    border-color: #f86c6b !important;
  } */
  
  .ant-btn-primary {
    color: #00ae41 !important;
    background-color: transparent !important;
    border-color: #59cc50 !important;
  }
  
  .ant-btn-primary:hover {
    color: white !important;
    background-color: #00ae41 !important;
    border-color: #00ae41 !important;
  }
  
  .btn-back {
    color:black;
    background-color: transparent;
    border-color: white;
  }
  
  .btn-back:hover {
    color: black;
    background-color: white;
    border-color: black;
  }

  
  .Btn-Submit {
    margin-left: 10px;
  }
  
  .btn-login {
    width: 100%;
    height: 40.5px;
    border-radius: 6px;
    border: solid 0.5px transparent;
    background-color: #295caa !important;
    color: white !important;
  }
  .btn-login:hover {
    width: 100%;
    height: 40.5px;
    border-radius: 6px;
    border: solid 0.5px transparent;
    background-color: white !important;
    color:#295caa !important;
  }
  .btn-forgot-back {
    width: 100%;
    height: 31.5px;
    border-radius: 6px;
    border: solid 0.5px transparent;
    background-color: #c8ced3;
    color: white;
  }
  .btn-forgot-back:hover {
    color: white;
    background-color: #c8ced3;
    border-color: #c8ced3;
  }
  
  .btn-signUp {
    width: 100%;
    height: 40.5px;
    border-radius: 6px;
    border: solid 0.5px #707070 !important;
    background-color: white;
    color: #43425d !important;
  }
  .btn-signUp:hover {
    width: 100%;
    height: 40.5px;
    border-radius: 6px;
    border: solid 0.5px transparent;
    background-color: #74a2e6 !important;
    color:white !important;
  }
  .btn-back:hover {
    color: #00ae41;
    background-color: #c8ced3;
    border-color: #59cc50;
  }
  
   .btn-success {
    color:#59cc50 !important;
    background-color: transparent !important;
    border-color: #59cc50 !important  ;
  }
  
  .btn-success:hover {
    color: white !important;
    background-color: #59cc50 !important;
    border-color: #59cc50 !important;
  }
  
 
  .btn-fail {
    color: #f86c6b;
    background-color: transparent;
    border-color: #f86c6b;
  }

  .btn-fail:hover {
    color: #f86c6b !important;
    border-color: #f86c6b !important; 
  } 
 
  .btn-info {
    color: #636464 !important;
    background-color: transparent !important;
    border-color: transparent!important; 
  }

  .btn-info:hover {
    color: #20a8d8 !important;
    background-color: transparent; 
  } 
  

  .btn-hapus {
    color: #636464;
    background-color: transparent !important;
    border-color: transparent!important; 
  }

  .btn-hapus:hover {
    color: #f86c6b;
    background-color: transparent;
  }

  .btn-setting{
    color:#636464;
    background-color: transparent;
    border-color:#636464;
  }
  .btn-setting:hover{
    color: #1B6AE4 !important;
    background-color: transparent;
  }


  .btn-notif{
    color:#1B6AE4 !important;
    background-color: transparent;
    border-color:#1B6AE4;
  }
  .btn-notif:hover{
    color: #1B6AE4 !important;
    background-color: transparent;
  }
 

  .btn-user{
    color:#636464;
    background-color: transparent !important;
    border-color:transparent !important;
  }
  .btn-user:hover{
    color: #59cc50 !important;
    background-color: transparent;
    border-color:transparent; 
  }
  .btn-pdf {
    color: #636464;
    background-color: transparent;
    border-color: #ffcb0f !important; 
  }
  
  .btn-pdf:hover {
    color: white;
    background-color:#ffcb0f !important;
    border-color: #ffcb0f !important ;
  }

  .btn-danger {
    color: #636464;
    background-color: transparent;
    border-color:#f86c6b; 
  }
  .btn-danger:hover {
    color: white;
    background-color: #f86c6b;
    border-color: #f86c6b;
  }
  
  .btn-back {
    
    color: #f86c6b !important;
    background-color: transparent !important;
    border-color: #f86c6b !important; 
  }
  .btn-back:hover {
   
    color: white !important;
    background-color: #f86c6b !important;
    border-color: #f86c6b !important;
  }
  .btn-save{
    
    color: #59cc50;
    background-color: transparent;
    border-color: #59cc50; 
  }
  .btn-save:hover {
   
    color: white;
    background-color: #59cc50;
    border-color: #59cc50;
  }
  .btn-secondary {
    color: #636464;
    background-color: transparent;
    border-color: transparent; 
  }
  .btn-secondary:hover {

    color: white;
    background-color: #636464;
    border-color: #636464;
  }
  
  .btn-warning {
    color: #636464;
    background-color: transparent;
    border-color: #e0a800; 
  }
  .btn-warning:hover {
    color: white;
    background-color: #e0a800;
    border-color: #e0a800;
  }
  
  .btn-primary-sm {
    color: #636464;;
    background-color: transparent;
    border-color: #29a7f2; 
  }
  
  .btn-Blue {
    /* color: #20a8d8; */
    background-color: #29a7f2 ;
    border-color: #29a7f2 ;
  }
  .btn-primary-sm:hover {
    color: white;
    background-color: #29a7f2 ;
    border-color: #29a7f2 ;
  }
  
  .btn-login-sm {
    color: White;
    background-color: #ffcb0f;
    border-color: #ffcb0f;
  }
  
  .btn-login-sm:hover {
    color: white;
    background-color: #d3a80c;
    border-color: #d3a80c;
  }
  
  .btn-formOnline {
    color: #43425d;
    background-color: transparent;
    border-color: #d9d9d9;
  }
  
  .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: rgb(216, 8, 8);
    border: 2px solid #d9d9d9;
    color: #fff;
    text-align: center;
    font: 13px Arial, sans-serif;
  }

  .numberTrue {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: rgb(21, 211, 84);
    border: 2px solid #d9d9d9;
    color: #fff;
    text-align: center;
    font: 13px Arial, sans-serif;
  }
  
  .numberCentre {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .flex-item{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 12px;
    background: rgb(216, 8, 8);
    border: 2px solid #d9d9d9;
    color: #fff;
    margin-left: 10px;
    margin-bottom: 5px;
    text-align: center;
    font: 17px Arial, sans-serif;
  }

  .flex-container{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-left: 800px;
    margin-top: -200px;
    margin-bottom: 100px;
    justify-content: start;
    background-color: #fff;;
    overflow: scroll;
    width: 30%;
    padding: 0;
  }
  .questionBody{
    /* display: flex; */
    flex-direction: row;
    /* flex-grow: 1; */
    /* flex-wrap: wrap; */
    /* margin-left: 800px; */
    justify-content:space-between;
    background-color: #fff;
  }

  .btn-formOnline:hover {
    color: white;
    background-color: #20a8d8;
    border-color: #20a8d8;
  }
  .title {
    /* font-family: SourceSansPro-Bold; */
    /* font-weight: bold; */
  }